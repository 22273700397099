<template>
  <div class="tab-content">
    <div class="pagenamPT">
      <h3 class="pagename2">전체 게임별</h3>
      <date-filter-pt/>
    </div>
    <article class="content-wrap">
      <table class="statTable">
        <thead>
          <tr>
            <th>공급사</th>
            <th>게임명</th>
            <th class="text-right">{{ $t('front.stributor.m22') }}</th>
            <th class="text-right">{{ $t('front.stributor.m23') }}</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PragmaticPlay(BT)</td>
            <td>파이어 포탈스</td>
            <td class="text-right">5,156,400</td>
            <td class="text-right">4,061,654</td>
            <td>-</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2"></td>
            <td class="text-right">19,415,715</td>
            <td class="text-right">17,960,451</td>
            <td>-</td>
          </tr>
        </tfoot>
      </table>
    </article>
  </div>
</template>
<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
export default {
  name: 'allGames',
  components: { DateFilterPt },
  data () {
    return {
    }
  }
}
</script>
<style src="@/styles/pt.css"></style>
